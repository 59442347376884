import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Image from 'next/image';

const LandingPageLogoList = ({ screenSizes, wrapperClassName, title, description, heading, logos, grayscale }) => {
  return (
    <section className={wrapperClassName}>
      { title && (
        <h2 className="mw--528 ta-center m-horizontal--auto">{ title }</h2>
      )}
      { description && (
        <p className="mw--660 ta-center m-top--medium m-horizontal--auto">{ description }</p>
      )}
      { heading && (
        <h6 className="ta-center">{ heading }</h6>
      )}
      <div className="landing-page-logo-list m-top--small-gt-xs m-horizontal--auto layout-row layout-align-center-center layout-wrap">
        { logos.map((logo, index) => (
          <div className={`m-top--xx-large m-horizontal--x-large-gt-xs m-horizontal--medium ${grayscale ? 'grayscale' : ''}`} key={index}>
            { logo.link ? (
              <a className="hover-none" href={logo.link} rel="noopener noreferrer" target="_blank">
                <Image alt={logo.imgAlt} height={screenSizes.get('xs') ? logo.height * .6 : logo.height} src={logo.imgSrc} width={screenSizes.get('xs') ? logo.width * .6 : logo.width} />
              </a>
            ) : (
              <Image alt={logo.imgAlt} height={screenSizes.get('xs') ? logo.height * .6 : logo.height} src={logo.imgSrc} width={screenSizes.get('xs') ? logo.width * .6 : logo.width} />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

LandingPageLogoList.propTypes = {
  // Required
  logos: PropTypes.array.isRequired,
  screenSizes: PropTypes.object.isRequired,
  // Optional
  description: PropTypes.string,
  grayscale: PropTypes.bool,
  heading: PropTypes.string,
  title: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

LandingPageLogoList.defaultProps = {
  description: '',
  grayscale: true,
  heading: '',
  title: '',
  wrapperClassName: 'p-horizontal--large p-horizontal--xx-large-gt-xs p-vertical--x-large p-vertical--xxx-large',
};

const mapStateToProps = (state) => ({
  screenSizes: state.get('ScreenSizes'),
});

export default connect(mapStateToProps, {})(LandingPageLogoList);
