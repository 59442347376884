import React  from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import TopCitiesStatic from '@utilities/static/TopCitiesStatic';

const TopCities = ({ showBrowseAllLink, wrapperClassName }) => (
  <section className={`${wrapperClassName} relative mw--md m-horizontal--auto`}>
    <div className="gallery">
      <h2>Find rentals in 100+ US cities</h2>
      <p className="mw--660 m-top--small">Through Kopa, thousands of renters find a rental for their next job, exploring a new city, relocating, renovating, going on a business trip, or visiting family and friends.</p>
      { showBrowseAllLink && (
        <Link href="/c">
          <a className="inline-block underline chevron-link m-top--small">
            Browse all rentals
          </a>
        </Link>
      )}
      <div className="m-horizontal--x-small-negative">
        { TopCitiesStatic.map((city, index) => (
          <div className="item m-top--x-large p-right--x-small p-left--x-small overflow-hidden" key={`top-city-${index}`}>
            <Link href={`/c/${city.state.slug}/${city.slug}`}>
              <a className="black opacity-hover">
                <Image alt={city.imgAlt} className="ellipsis b--rounded-sm" height={253} src={`${city.img}@3x.jpg`} width={190} />
              </a>
            </Link>
            <Link href={`/c/${city.state.slug}/${city.slug}`}>
              <a className="black underline">
                <h4 className="m-top--x-small">{ city.name }</h4>
              </a>
            </Link>
            <p className="meta m-top--xx-small">{ city.state.name ? city.state.name + ', ' : '' }{ city.country }</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

TopCities.propTypes = {
  // Optional
  showBrowseAllLink: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

TopCities.defaultProps = {
  showBrowseAllLink: false,
  wrapperClassName: '',
};

export default TopCities;
