import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import qs from 'query-string';
import Footer from '@components/Footer/Footer';
import Head from '@components/Core/Head';
import { ArrowForwardIcon } from '@components/Icons';
import Checklist from '@components/LandingPages/Checklist';
import FeaturedListings from '@components/LandingPages/FeaturedListings';
import RecentPress from '@components/LandingPages/RecentPress';
import RenterValueProps from '@components/LandingPages/RenterValueProps';
import SearchBlock from '@components/LandingPages/SearchBlock';
import Testimonials from '@components/LandingPages/Testimonials';
import TopCities from '@components/LandingPages/TopCities';
import { withPageWrapper } from '@components/PageWrappers/CorePageWrapper';
import Actions from '@redux/actions';
import { wrapper } from '@redux/store';
import ImageService from '@services/ImageService';
import SearchFilterUtility from '@utilities/SearchFilterUtility';

const CHECKLIST_ITEMS = [
  'Fully furnished spaces',
  'Ready-to-use wifi',
  'Tour and book online',
];

const TESTIMONIAL_ITEMS = [{
  quote: 'Your website is really slick. You’ve made a much better process for adding a listing than Zillow and all the other rental platforms.',
  name: 'Justin',
  title: 'Host in Seattle',
}, {
  quote: 'Kopa has been extremely helpful in remediating the natural stresses of finding an apartment.',
  name: 'Vaughn',
  title: 'Renter in NYC',
}, {
  quote: 'Kopa allows us to find great residents for our properties easily. Kopa renters who inquire are always ready to book and easy to work with.',
  name: 'Astro Living',
  title: 'Host in Bay Area',
}, {
  quote: 'We found a lovely place with a thoughtful landlord and fair price. The whole process was a breeze, and Kopa’s team went above and beyond.',
  name: 'Athos',
  title: 'Renter in LA',
}, {
  quote: 'I’m very excited to begin working with your team. I like your model better than Airbnb or VRBO. I hope to build a great partnership.',
  name: 'Jeffrey',
  title: 'Host in Atlanta',
}, {
  quote: 'Between spam listings on Craigslist and high fees on Airbnb, I really do hope Kopa becomes a success for all our sakes.',
  name: 'Syed',
  title: 'Renter in San Francisco',
}, {
  quote: 'Your support is very impressive! Your team has been amazingly helpful and responsive!',
  name: 'Lillian',
  title: 'Host in Philadelphia',
}, {
  quote: 'Kopa is so awesome! I really love the commute feature so I can see how long it will take me to get to work. It’s so useful!',
  name: 'Maya',
  title: 'Renter in Boston',
}];

export const getServerSideProps = wrapper.getServerSideProps(
  async (ctx) => {
    const { store } = ctx;

    try {
      const featuredListingsQuery = {
        limit: 16,
      };
      await store.dispatch(
        Actions.retrieveFeaturedListings(qs.stringify(featuredListingsQuery))
      );

      return {};
    } catch (error) {
      return {};
    }
  }
);

const HomePage = ({ filters }) => (
  <>
    <Head />

    <section className="relative p-vertical--x-large-lt-sm p-vertical--xxx-large-gt-xs">
      <div className="mw--md m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs layout-gt-sm-row">
        <div className="w--80p-lt-md flex-order-gt-sm-2">
          <Image alt="The many doors of Kopa illustration" height={424} priority src={ImageService.doorsOfKopa} width={547} />
        </div>
        <div className="mw--560-gt-sm m-top--medium-lt-md m-right--xx-large-gt-sm">
          <h1 className="huge">Find monthly rentals and roommates</h1>
          <p className="m-top--medium">Find home rentals, apartments, and rooms for rent for 1–12+ month stays in over 100 cities and towns across the United States.</p>
          <div className="m-top--x-large">
            <SearchBlock
              dateFormat="MMM d"
              isInline={false}
              searchBlockId="home-hero"
            />
          </div>
        </div>
      </div>
    </section>

    <div className="recent-press m-vertical--xx-large">
      <RecentPress />
    </div>

    <RenterValueProps />

    <TopCities showBrowseAllLink wrapperClassName="p-horizontal--large p-vertical--x-large-lt-sm p-vertical--xxx-large-gt-xs" />

    <section className="wfh white m-vertical--x-large m-vertical--xxx-large-gt-xs p-vertical--xxxx-large">
      <div className="mw--md m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs layout-gt-xs-row layout-align-space-between-center">
        <div className="p-right--x-large-gt-xs p-vertical--medium flex-gt-xs-50">
          <h2 className="m-top--x-small">Work from home.<br />Wherever that may be. </h2>
          <p className="m-top--small">With millions of people working from home, our Work from Home program allows you to find rentals that are perfect for working remotely.</p>
          <Checklist items={CHECKLIST_ITEMS} wrapperClassName="m-top--x-large" />
          <Link href="/c">
            <a className="inline-flex white underline m-top--x-large">
              Browse Work from Home rentals
              <ArrowForwardIcon className="icon--14 icon-stroke--sm m-left--x-small" />
            </a>
          </Link>
        </div>
        <div className="inline-flex b--rounded overflow-hidden m-top--x-large-lt-sm">
          <Image alt="Digital nomad working remotely" height={432} src={ImageService.digitalNomadWorkingRemotely} width={577} />
        </div>
      </div>
    </section>

    <FeaturedListings link={SearchFilterUtility.getListingSearchRedirect(filters)} title="Popular rental properties" type="elasticsearch" />

    <Testimonials items={TESTIMONIAL_ITEMS} wrapperClassName="home-testimonials relative z--1 m-bottom--medium-negative p-vertical--xx-large-lt-sm p-vertical--xxx-large-gt-xs" />

    <SearchBlock
      description="Find your next rental anywhere you're moving — from Los Angeles to New York City and beyond."
      formClassName="bg--gray-3 mw--1024 black"
      searchBlockId="home-bottom"
      title="Find your next rental"
      wrapperClassName="get-started white m-bottom--medium-negative p-vertical--xxxx-large-lt-sm p-vertical--xxxxx-large-gt-xs"
    />

    <Footer />
  </>
);

HomePage.propTypes = {
  // Required
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.get('Search').get('filters'),
});

export default withPageWrapper(HomePage, {
  mapStateToProps,
  props: {
    pageClassName: 'overflow-hidden',
    navClassName: 'sticky b-bottom--xs b--gray-2 a--top',
  },
});
