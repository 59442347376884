import React from 'react';
import Image from 'next/image';
import ImageService from '@services/ImageService';

const RenterValueProps = () => (
  <section className="mw--md m-horizontal--auto relative p-horizontal--large p-horizontal--x-large-gt-xs p-vertical--x-large-lt-sm p-vertical--xxx-large-gt-xs">
    <h2>Move-in ready spaces</h2>
    <p className="m-top--small">Hosts have added over 20,000 spaces across the United States.<br />Browse, book, and pay for your next rental on Kopa.</p>
    <div className="layout-row layout-align-center-start layout-wrap">
      <div className="m-top--xx-large p-right--medium-gt-xs p-right--x-large-gt-sm flex-100 flex-gt-xs-50 flex-gt-sm-25">
        <div className="h--96 layout-row layout-align-start-center">
          <Image alt="Verified government-issued ID illustration" height={64} src={ImageService.verifiedUsers} width={86} />
        </div>
        <div className="m-top--medium-lt-sm">
          <h3>Prices for your budget</h3>
          <p className="mw--352-gt-xs">Find unfurnished rooms for rent to luxury apartments in big cities and small towns across the US.</p>
        </div>
      </div>
      <div className="m-top--xx-large p-left--medium-gt-xs p-left--x-large-gt-sm flex-100 flex-gt-xs-50 flex-gt-sm-25">
        <div className="h--96 layout-row layout-align-start-center">
          <Image alt="Compatible roommates high-fiving illustration" height={86} src={ImageService.compatibleHousemates} width={74} />
        </div>
        <div className="m-top--medium-lt-sm">
          <h3>Flexible stays</h3>
          <p className="mw--352-gt-xs">No more year-long leases. Find a space for any length of stay between 1-12+ months.</p>
        </div>
      </div>
      <div className="m-top--xx-large p-right--medium-gt-xs p-right--x-large-gt-sm flex-100 flex-gt-xs-50 flex-gt-sm-25">
        <div className="h--96 layout-row layout-align-start-center">
          <Image alt="Bed with linens ready for a tenant illustration" height={76} src={ImageService.rentalAmenities} width={92} />
        </div>
        <div className="m-top--medium-lt-sm">
          <h3>All the essentials</h3>
          <p className="mw--352-gt-xs">Browse apartments with a range of amenities and furnishings to fit your budget and needs.</p>
        </div>
      </div>
      <div className="m-top--xx-large p-left--medium-gt-xs p-left--x-large-gt-sm flex-100 flex-gt-xs-50 flex-gt-sm-25">
        <div className="h--96 layout-row layout-align-start-center">
          <Image alt="Clicking on a home to rent illustration" height={68} src={ImageService.browseRentals} width={91} />
        </div>
        <div className="m-top--medium-lt-sm">
          <h3>Everything is online</h3>
          <p className="mw--352-gt-xs">Easily book listings, chat with hosts, sign leases, and pay deposits and monthly rent.</p>
        </div>
      </div>
    </div>
  </section>
);

export default RenterValueProps;
